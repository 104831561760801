import { useEffect } from 'react';
import { ENERGY_EFFICIENCY_HASH } from '../../constants/energyClass';
import { EventBusEvent } from '../../enums/EventBus';
import eventBus from '../../event-bus/EventBus';
import { useOpenDisclaimer } from '../../hooks/drawer';
import { useLocalization } from '../../providers/LocaleProvider';
var getRemoveEnergyHashHandler = function () {
    return function (_a) {
        var open = _a.open;
        if (!open && location.hash === ENERGY_EFFICIENCY_HASH) {
            history.pushState('', document.title, "".concat(location.pathname).concat(location.search));
        }
    };
};
export var useEnergyClass = function (_a) {
    var content = _a.content, labelUrls = _a.labelUrls, shouldHandleEnergyHash = _a.shouldHandleEnergyHash;
    var t = useLocalization().t;
    var openDrawer = useOpenDisclaimer({
        title: t('h24_energy_class_title'),
        content: content,
    });
    useEffect(function () {
        if (shouldHandleEnergyHash && labelUrls.length && location.hash === ENERGY_EFFICIENCY_HASH) {
            var removeEnergyHash_1 = getRemoveEnergyHashHandler();
            openDrawer();
            eventBus.on(EventBusEvent.ToggleDisclaimer, removeEnergyHash_1);
            return function () { return eventBus.off(EventBusEvent.ToggleDisclaimer, removeEnergyHash_1); };
        }
    }, []);
    return openDrawer;
};
